import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grid,
  Typography,
  Paper,
  Card,
  IconButton,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  Button,
  CircularProgress,
  Divider,
  List,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  QueryBuilder,
  Check,
  DoneAll,
  Block,
  Close,
  Send,
  ErrorOutline,
} from '@material-ui/icons';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { Skeleton, Alert } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import EnviarArquivo from '../../components/EnviarArquivo';
import DefaultPage from '../../templates/DefaultPage';
import {
  AutoCompleteSearchInput,
  RoundedButton,
  RoundedInput,
} from '../../components';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import PerfilModal from './PerfilModal';
import ConfirmCloseModal from '../../components/ConfirmCloseModal';
import DetailsModal from './DetailsModal';
import ToForwardModal from './ToForwardModal';
import useDebounce from '../../hooks/useDebounce';
import { getAdminOrOwnerRole } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  customButtonDefault: {
    borderRadius: '5px !important',
    marginLeft: '1em',
    marginTop: '5px',
  },
  input: {
    height: '65px',
    display: 'flex',
    width: '100%',
    margin: '0.8em 0em',
    borderRadius: '5px !important',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  containerCustom: {
    marginLeft: '3%',
    marginRight: '3%',
    width: '100%',
    '@media (max-width:600px)': {
      marginLeft: '3%',
      marginRight: '3%',
    },
  },
  messageArea: {
    backgroundColor: '#F4F4F4',
    height: '500px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#F5F5F5',
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D8D8D8',
      },
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3);',
      borderRadius: '3px',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: '#E6E6E6',
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3);',
    },
  },
  messageProp: {
    display: 'inline-block',
    maxWidth: '80%',
    borderRadius: '10px',
    padding: '5px 15px',
    backgroundColor: theme.palette.primary.main,
    '& .MuiListItemText-primary': {
      fontSize: '1em',
      color: '#F5F5F5',
    },
    '& .MuiListItemText-secondary': {
      fontSize: '0.8em',
      color: 'rgba(245, 245, 245, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      flexWrap: 'wrap',
    },
  },
  messageRem: {
    display: 'inline-block',
    maxWidth: '80%',
    borderRadius: '10px',
    padding: '5px 15px',
    borderWidth: '2px',
    '& .MuiListItemText-primary': {
      fontSize: '1em',
      color: '#3B3B3B',
    },
    '& .MuiListItemText-secondary': {
      fontSize: '0.8em',
      color: '#A0A0A0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      flexWrap: 'wrap',
    },
  },
  deleteMessageCustom: {
    display: 'flex',
    alignItems: 'center',
    color: '#dbdbdb',
    fontStyle: 'italic',
  },
  img: {
    maxWidth: '200px',
  },
  infoFile: {
    color: '#00796B',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    border: `1.5px solid ${theme.palette.text.secondary}`,
    borderRadius: theme.spacing(1),
  },
  downloadFile: {
    textDecoration: 'none',
  },
  divNomeRemetente: {
    display: 'flex',
    flexDirection: 'column',
  },
  formatNomeRementente: {
    fontSize: '12px',
    fontWeight: '600',
    color: '#575757',
  },
  divDataSeparator: {
    display: 'flex',
    justifyContent: 'center',
  },
  dateMessageSeparator: {
    fontSize: '12px',
    fontWeight: '700',
    color: '#7D7D7D',
  },
  divModoAdmTag: {
    marginBottom: '15px',
  },
}));

let listMessages = [];
let updateScroll = true;
let lockUpdate = false;

export default () => {
  const classes = useStyles();
  const setAlert = useAlert();
  const { idChat } = useParams();
  const debounce = useDebounce();
  const isAdminOrOwner = getAdminOrOwnerRole();

  const DEFAULT_LIMIT_USERS_PAGE = 15;

  const messagesEndRef = useRef(null);
  const textRef = useRef();

  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [firstMessage, setFirstMessage] = useState(null);
  const [lastMessage, setLastMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [messages, setMessages] = useState([]);
  const [sectorName, setSectorName] = useState('');
  const [openClose, setOpenClose] = useState(false);
  const [loadingClose, setLoadingClose] = useState(false);

  const [openDelete, setOpenDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [openDetails, setOpenDetails] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingReceived, setLoadingReceived] = useState(false);
  const [loadingVisualized, setLoadingVisualized] = useState(false);
  const [dataDetails, setDataDetails] = useState(null);
  const [pageReceived, setPageReceived] = useState(1);
  const [pageVisualized, setPageVisualized] = useState(1);
  const [receivedDetails, setReceivedDetails] = useState([]);
  const [visualizedDetails, setVisualizedDetails] = useState([]);

  const [nameProp, setNameProp] = useState('');
  const [labelProp, setLabelProp] = useState('');
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [activeClose, setActiveClose] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpenId, setMenuOpenId] = useState(null);
  const [editMessage, setEditMessage] = useState(null);

  const [chatAtivo, setChatAtivo] = useState(true);

  const [text, setText] = useState('');
  const [newText, setNewText] = useState('');

  const [perfilData, setPerfilData] = useState(null);
  const [openPerfil, setOpenPerfil] = useState(false);
  const [loadingPerfil, setLoadingPerfil] = useState(false);
  const [base64, setBase64] = useState('');
  const [file, setFile] = useState('');
  const [codigoSaidaEvento, setCodigoSaidaEvento] = useState(null);
  const [allowAnswer, setAllowAnswer] = useState(false);
  const [assunto, setAssunto] = useState('');
  const [isLote, setLote] = useState(null);

  const [openToForwardModal, setOpenToForwardModal] = useState(false);
  const [idMessage, setIdMessage] = useState(null);

  const [signaturePage, setSignaturePage] = useState({
    page: 1,
    lastPage: 1,
  });
  const [signatureInfo, setSignatureInfo] = useState({
    idSector: null,
    searchText: '',
  });
  const [signatureOptions, setSignatureOptions] = useState([]);
  const [signatureLoading, setSignatureLoading] = useState(false);
  const [selectedSignature, setSelectedSignature] = useState(null);
  const [adminMode, setAdminMode] = useState(
    isAdminOrOwner
      ? JSON.parse(localStorage.getItem('admMode')) ?? false
      : false
  );

  useEffect(() => {
    setAdminMode(
      isAdminOrOwner
        ? JSON.parse(localStorage.getItem('admMode')) ?? false
        : false
    );
  }, [isAdminOrOwner]);

  const messagesScrollToBottom = () => {
    const el = messagesEndRef.current;

    if (el) el.scrollTop = el.scrollHeight - el.clientHeight;
  };

  const handleUpdateMessages = () =>
    setMessages([...handleGroupMessages(listMessages)]);
  const handleChangeText = (value) => setText(value);
  const handleCloseMenu = () => setMenuOpenId(null);
  const handleCloseEdit = () => setEditMessage(null);

  const handleOpenMenu = (event, message) => {
    if (
      message.IdMensagem == null ||
      message.IdMensagem === -1 ||
      message.Excluida
    )
      return;

    setMenuOpenId(message.IdMensagem);
    setAnchorEl(event.currentTarget);
  };

  const handleOpenEditMessage = (id, message) => {
    setNewText(message.Mensagem);
    setEditMessage(id);
    handleCloseMenu();
  };

  const formatLabelProp = (studants) => {
    if (studants == null || studants.length === 0) setLabelProp('');
    else {
      const maxLength = 40;
      const messageTemp = `Responsável por: ${studants
        .map((e) => e.Nome)
        .join(', ')}`;

      const formatedMessage =
        messageTemp.length > maxLength
          ? `${messageTemp.substring(0, maxLength).trimEnd()}...`
          : messageTemp;

      setLabelProp(formatedMessage);
    }
  };

  const handleSendEnterPress = (textEnter, e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      setText(textEnter);
      sendMessage(textEnter, null, null);
    }
  };

  const handleEditMessageEnterPress = (message, newText, e) => {
    if (newText?.trim() === '') return;

    if (e.key === 'Enter' && !e.shiftKey) {
      setNewText(text);
      requestEditMessage(message, newText);
    }
  };

  const requestEditMessage = (message, newText) => {
    if (message.Mensagem === newText) {
      setEditMessage(null);
      return;
    }

    const index = listMessages.findIndex(
      (x) => x.IdMensagem === message.IdMensagem
    );
    listMessages[index].Mensagem = newText;

    handleUpdateMessages();
    handleCloseEdit();
    handleEditMessage(message, newText);
  };

  const handleAlert = (message, type) => {
    setAlert({
      isOpen: true,
      type: type ?? 'error',
      message: message ?? 'Ocorreu um erro inesperado!',
    });
  };

  const handleErrorChat = (message) => {
    setIsLoading(false);
    setIsLoadingMore(false);

    setAlert({
      isOpen: true,
      type: 'error',
      message: message ?? 'Ocorreu um erro inesperado!',
    });
  };

  const handleErrorPerfil = (message) => {
    setOpenPerfil(false);

    setAlert({
      isOpen: true,
      type: 'error',
      message:
        message ?? 'Ocorreu um erro inesperado ao carregar perfil de usuário!',
    });

    setLoadingPerfil(false);
    setPerfilData(null);
  };

  const handleWaitCloseChat = () => {
    const activeCloseTemp = !activeClose;
    setActiveClose(activeCloseTemp);

    handleEditChat({ requestClose: activeCloseTemp });
  };

  const confirmCloseChat = () => {
    setLoadingClose(true);
    handleEditChat({ active: false });
  };

  const handleForceCloseChat = () => {
    if (chatAtivo) setOpenClose(true);
    else
      setAlert({
        isOpen: true,
        type: 'warning',
        message: 'Esta conversa já foi encerrada.',
      });
  };

  const handleResetCloseChat = () => {
    setLoadingClose(false);
    setOpenClose(false);
  };

  const handleEditChat = (param) => {
    const registerData = {
      idChat,
      ...param,
    };

    api
      .put('chat/editChatSector', registerData)
      .then((res) => {
        if (!res.data.Sucesso) {
          handleAlert(res.data.Mensagem, 'error');
          handleResetCloseChat();
        } else {
          if (res.data.Dados.Ativo === false) setChatAtivo(false);

          handleResetCloseChat();
        }
      })
      .catch(() => {
        handleAlert(null, 'error');
        handleResetCloseChat();
      });
  };

  const handleDeleteMessage = (idMessage) => {
    handleCloseMenu();
    setOpenDelete(idMessage);
  };

  const handleResetDelete = () => {
    setLoadingDelete(false);
    setOpenDelete(null);
  };

  const handleConfirmDeleteMessage = (idMessage) => {
    setLoadingDelete(true);

    const params = {
      idMessage,
      adminMode,
    };

    api
      .delete('chat/deleteMessageSector', {
        params,
      })
      .then((res) => {
        if (!res.data.Sucesso) {
          handleAlert(res.data.Mensagem, 'error');
          handleResetDelete();
        } else {
          const index = listMessages.findIndex(
            (x) => x.IdMensagem === idMessage
          );
          listMessages[index].Excluida = true;
          handleUpdateMessages();
          handleResetDelete();
        }
      })
      .catch(() => {
        handleAlert(null, 'error');
        handleResetDelete();
      });
  };

  const handleEditMessage = (message, newText) => {
    if (newText?.trim() === '') return;

    const registerData = {
      idChat,
      idMessage: message.IdMensagem,
      message: newText,
      adminMode,
    };

    api
      .put('chat/editMessageSector', registerData)
      .then((res) => {
        if (!res.data.Sucesso) handleAlert(res.data.Mensagem, 'error');
        else {
          const index = listMessages.findIndex(
            (x) => x.IdMensagem === message.IdMensagem
          );
          listMessages[index].Editado = true;
          handleUpdateMessages();
        }
      })
      .catch(() => {
        handleAlert(
          'Ocorreu um erro inesperado ao tentar editar mensagem.',
          'error'
        );
      });
  };

  const sendMessage = (message, currentBase64, currentfile) => {
    if ((message?.trim() === '' && currentBase64 == null) || !chatAtivo) return;

    const fileType = currentfile !== null ? currentfile.type : null;
    const newMessage = {
      Excluida: false,
      IdMensagem: null,
      Mensagem: message,
      DataEnvio: Date.now(),
      ProprietarioMensagem: true,
      Visualizada: false,
      Arquivo: currentfile,
      TipoArquivo: fileType,
    };

    const index = listMessages.push(newMessage) - 1;
    updateScroll = true;
    handleUpdateMessages();

    const registerData = {
      idChat,
      message: message?.trim() === '' ? ' ' : message,
      fileName: currentfile !== null ? currentfile.name : null,
      fileType,
      codigoSaidaEvento,
      conteudo:
        currentfile !== null ? currentBase64.split(',')[1] : currentBase64,
    };

    setText('');
    textRef.current.value = '';

    api
      .post('chat/sendMessageSector', registerData)
      .then((res) => {
        if (!res.data.Sucesso) {
          listMessages.splice(index, 1);
          handleErrorChat();
        } else {
          listMessages[index].IdMensagem = res.data.Dados.Id;
        }
        handleUpdateMessages();
      })
      .catch(() => {
        listMessages.splice(index, 1);
        handleUpdateMessages();
      });
  };

  const handleLoadMoreMessages = () => {
    updateScroll = false;
    setIsLoadingMore(true);
    handleLoadMessage(page + 1, { before: firstMessage });
  };

  useEffect(() => {
    listMessages = [];
    lockUpdate = false;
    setIsLoading(true);
    handleLoadMessage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (updateScroll) messagesScrollToBottom();
    // eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    let interval = null;

    if (idChat != null && lastMessage != null && chatAtivo)
      interval = setInterval(handleUpdateChat, 4000);

    return () => clearInterval(interval);
  }, [idChat, lastMessage, chatAtivo]);

  const handleUpdateChat = () => {
    if (lockUpdate) return;

    if (idChat != null && lastMessage != null) {
      lockUpdate = true;

      const params = {
        idChat,
        after: lastMessage,
        adminMode,
      };

      api
        .get('chat/getChatUpdateSector', {
          params,
        })
        .then((response) => {
          if (response.data.Sucesso) {
            setChatAtivo(response.data.Dados.Ativo);

            const messagesTemp = response.data.Dados.Mensagens;

            if (messagesTemp.length > 0) {
              [...messagesTemp].forEach((x) => {
                const index = listMessages.findIndex(
                  (l) => l.IdMensagem === x.IdMensagem
                );

                if (index >= 0) listMessages[index] = x;
                else listMessages.push(x);
              });

              handleUpdateMessages();
            }
          }

          lockUpdate = false;
        })
        .catch(() => (lockUpdate = false));
    }
  };

  const hadleRequestSignature = (
    pageParam = 1,
    idSectorParam = null,
    selectedUserParam = null,
    searchText = null
  ) => {
    setSignatureLoading(true);
    const params = {
      idSector: idSectorParam ?? signatureInfo.idSector,
      page: pageParam,
      limit: DEFAULT_LIMIT_USERS_PAGE,
      name: searchText,
    };

    api
      .get('sector/getUsersBySector', { params })
      .then((response) => {
        const data = response?.data?.data ?? [];
        const options = pageParam > 1 ? [...signatureOptions, ...data] : data;
        const selectedTemp = selectedSignature ?? selectedUserParam;

        setSignaturePage({
          page: pageParam,
          lastPage: response?.data?.lastPage ?? 1,
        });
        setSignatureOptions(options);

        if (selectedUserParam) setSelectedSignature(selectedTemp);
      })
      .finally(() => setSignatureLoading(false));
  };

  const handleLoadMoreSignature = (event) => {
    event.preventDefault();
    hadleRequestSignature(signaturePage.page + 1);
  };

  const handleLoadSignature = (dataParam) => {
    const idSector = dataParam?.IdSetor;

    if (!idSector) return;

    setSignatureInfo({ ...signatureInfo, idSector });
    hadleRequestSignature(1, idSector, dataParam?.UsuarioAtendente);
  };

  const handleChangeSignatureTextDebouce = debounce((textParam) =>
    hadleRequestSignature(1, null, null, textParam)
  );

  const handleChangeSignatureText = (event, textParam) => {
    if (event?.type === 'change' || !textParam) {
      setSelectedSignature(null);
      handleChangeSignatureTextDebouce(textParam);
    }
  };

  const handleChageSignatureValue = (event, value) => {
    setSelectedSignature(value);
    api.put('chat/subscribeChatSector', { idChat, idUser: value?.id });
  };

  const handleLoadMessage = (pag = 1, extraParams = null) => {
    const params = {
      idChat,
      page: pag,
      limit: 25,
      ...extraParams,
      adminMode,
    };

    api
      .get('chat/getChatMessagesSectors', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso) handleErrorChat(response.data.Mensagem);
        else {
          listMessages.unshift(...response.data.Dados.Data);
          handleUpdateMessages();
          setLastPage(response.data.Dados.LastPage);
          setPage(pag);
          setIsLoading(false);
          setIsLoadingMore(false);

          if (pag === 1) {
            if (listMessages.length > 0) {
              setFirstMessage(
                listMessages[listMessages.length - 1].IdMensagem + 1
              );
              setLastMessage(listMessages[0].IdMensagem - 1);
            }

            messagesScrollToBottom();
          }
        }
      })
      .catch(() => handleErrorChat(null));
  };

  useEffect(() => {
    setLoadingPerfil(true);

    const params = {
      idChat,
      adminMode,
    };

    api
      .get('chat/getChatInfoSector', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso) {
          handleErrorPerfil(response.data.Mensagem);
        } else {
          handleLoadSignature(response.data.Dados);
          setSectorName(response.data.Dados.NomeSetor);
          setCodigoSaidaEvento(response.data.Dados.CodigoSaidaEvento);
          setPerfilData(response.data.Dados);
          setNameProp(response.data.Dados.NomeProprietario);
          formatLabelProp(response.data.Dados.ResponsavelPor);
          setActiveClose(response.data.Dados.SolicitaEncerramento);
          setAvatarUrl(response.data.Dados.FotoProprietario);
          setChatAtivo(response.data.Dados.Ativo);
          setAllowAnswer(response.data.Dados.PermiteRespostas);
          setAssunto(response.data.Dados.Assunto);
          setLote(response.data.Dados.IsLote);
          setLoadingPerfil(false);
        }
      })
      .catch(() =>
        handleErrorPerfil(
          'Ocorreu um erro inesperado ao tentar buscar informações do setor'
        )
      );
    // eslint-disable-next-line
  }, []);

  const RenderInfoMessage = (message) => {
    if (message.Excluida) return null;

    return (
      <>
        {message.MensagemEncaminhada
          ? `Mensagem encaminhada - ${
              message.NomeSetorEncaminhouMsg != null
                ? `${message.NomeSetorEncaminhouMsg} - `
                : ''
            }`
          : ''}
        {(message.IdLote != null ? 'Enviado em lote - ' : '') +
          moment(message.DataEnvio).format('HH:mm')}
        {message.Editado && <span style={{ marginLeft: '4px' }}>Editada</span>}
        {(message.ProprietarioMensagem || message.TipoUsuarioRemetente === 2) &&
          RenderIconSend(message)}
      </>
    );
  };

  const RenderViewMessage = (message) => {
    return (
      <>
        {message.Excluida ? (
          <span className={classes.deleteMessageCustom}>
            <Block fontSize="small" style={{ marginRight: '5px' }} /> Mensagem
            excluída
          </span>
        ) : message.ProprietarioMensagem ||
          message.TipoUsuarioRemetente === 2 ? (
          <div style={{ whiteSpace: 'pre-wrap' }}>{message.Mensagem}</div>
        ) : (
          <div className={classes.divNomeRemetente}>
            <span className={classes.formatNomeRementente}>
              {message.NomeProprietario}
            </span>
            <div style={{ whiteSpace: 'pre-wrap' }}>{message.Mensagem}</div>
          </div>
        )}
      </>
    );
  };

  const RenderIconSend = (message) => {
    const styleTemp = { fontSize: 15, marginLeft: '4px' };

    if (!message.IdMensagem) return <QueryBuilder style={styleTemp} />;

    if (message.IdMensagem === -1) return <ErrorOutline style={styleTemp} />;

    if (message.VisualizadaPorTodos)
      return <DoneAll style={{ ...styleTemp, color: '#00B940' }} />;

    if (message.StatusEnvio === 'Enviado') return <DoneAll style={styleTemp} />;

    return <Check style={styleTemp} />;
  };

  const handleSetDataDetails = (data, start) => {
    const receivedDetailsTemp = start ? [] : [...receivedDetails.data];
    const visualizedDetailsTemp = start ? [] : [...visualizedDetails.data];

    if (start)
      setDataDetails({ DataEnvio: data.DataEnvio, IdMessage: data.IdMensagem });

    if (data.UsuarioRecebido) {
      const receivedTemp = data.UsuarioRecebido.Data.filter(
        (x) => !x.Proprietario
      );
      setReceivedDetails({
        lastPage: data.UsuarioRecebido.LastPage,
        data: [...receivedDetailsTemp, ...receivedTemp],
      });
    }

    if (data.UsuarioVisualizado) {
      const visualizedTemp = data.UsuarioVisualizado.Data.filter(
        (x) => !x.Proprietario
      );
      setVisualizedDetails({
        lastPage: data.UsuarioVisualizado.LastPage,
        data: [...visualizedDetailsTemp, ...visualizedTemp],
      });
    }
  };

  const handleLoadMoreVisualized = () => {
    if (pageVisualized >= visualizedDetails.lastPage) return;

    setLoadingVisualized(true);
    const pageTemp = pageVisualized + 1;
    setPageReceived(pageTemp);

    handleGetInfoMessage(dataDetails.IdMessage, {
      page: pageTemp,
      getReceived: false,
    });
  };

  const handleLoadMoreReceived = () => {
    if (pageReceived >= receivedDetails.lastPage) return;

    setLoadingReceived(true);
    const pageTemp = pageReceived + 1;
    setPageReceived(pageTemp);

    handleGetInfoMessage(dataDetails.IdMessage, {
      page: pageTemp,
      getVisualized: false,
    });
  };

  const resetDetails = () => {
    setLoadingDetails(false);
    setLoadingReceived(false);
    setLoadingVisualized(false);
  };

  const handleGetInfoMessage = (idMessage, addParams, start = false) => {
    if (start) {
      setPageReceived(1);
      setPageVisualized(1);
      setLoadingDetails(true);
      setOpenDetails(true);
      handleCloseMenu();
    }

    const params = {
      idMessage,
      limit: 15,
      ...addParams,
    };

    api
      .get('chat/getMessageInfoSector', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso) {
          resetDetails();
          handleAlert(response.data.Mensagem, 'error');
        } else {
          handleSetDataDetails(response.data.Dados, start);
          resetDetails();
        }
      })
      .catch(() => {
        resetDetails();
        handleAlert(null, null);
      });
  };

  const handleScroll = (e) => {
    const diff = e.target.scrollHeight - e.target.clientHeight;
    const onBottom = diff <= e.target.scrollTop + 15;

    if (onBottom) updateScroll = true;
    else updateScroll = false;
  };

  const sendFile = () => {
    sendMessage(text, base64, file);
  };

  const downloadFile = (e) => {
    e.stopPropagation();
  };

  const handleAllowAnswer = (statusAllowAnswer) => {
    setAllowAnswer(statusAllowAnswer);
    handleChengeAllowAnswer(statusAllowAnswer);
  };

  const handleChengeAllowAnswer = async (statusAllowAnswer) => {
    const params = {
      permiteResposta: statusAllowAnswer,
      idChatSetor: idChat,
    };

    api
      .put('chat/updateAllowAnswerSingle', params)
      .then(() => {})
      .catch(() => handleAlert(null));
  };

  const handleGroupMessages = (listMessages) => {
    const group = [];
    let currentData = null;
    listMessages.forEach((m) => {
      const newDate = moment(m.DataEnvio).format('DD/MM/YYYY');
      if (currentData != newDate) {
        currentData = newDate;
        group.push({
          data: currentData,
          mensagens: [m],
        });
      } else {
        group.find((e) => e.data == currentData).mensagens.push(m);
      }
    });
    return group;
  };

  const handleOpenToForward = (message) => {
    setIdMessage(message?.IdMensagem);
    setOpenToForwardModal(true);
  };

  const statusMap = {
    false: {
      true: 'Em andamento',
      false: 'Finalizado',
    },
    true: {
      true: 'Aguardando Finalização',
      false: 'Finalizado',
    },
  };

  const PaperComponent = useCallback(
    ({ children }) => {
      return (
        <Paper>
          {children}
          <Button
            color="primary"
            disabled={signaturePage.page >= signaturePage.lastPage}
            fullWidth
            onMouseDown={handleLoadMoreSignature}
          >
            Carregar mais
          </Button>
        </Paper>
      );
    },
    [signaturePage, signatureOptions]
  );

  return (
    <DefaultPage
      title={
        isLote
          ? `Mensagem lote | ${assunto != null ? assunto : ''}`
          : `Mensagem | ${assunto != null ? assunto : ''}`
      }
    >
      <div className={classes.containerCustom}>
        <div style={{ marginTop: '2em' }}>
          <div hidden={!adminMode} className={classes.divModoAdmTag}>
            <Alert severity="warning" style={{ fontSize: '12px' }}>
              Modo Administrador - Permite apenas a edição de mensagens já
              enviadas por qualquer setor. Não é possível enviar novas mensagens
              ou realizar outras ações.
            </Alert>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={9} lg={8} xl={8}>
              <Grid direction="row" alignItems="center" container spacing={3}>
                <Grid item xs={7}>
                  <ListItem style={{ paddingLeft: '0' }}>
                    <ListItemAvatar>
                      {isLoading ? (
                        <Skeleton variant="circle" width={40} height={40} />
                      ) : (
                        <Avatar src={avatarUrl}>
                          {nameProp[0]?.toUpperCase()}
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        isLoading ? <Skeleton variant="text" /> : nameProp
                      }
                      secondary={
                        isLoading ? <Skeleton variant="text" /> : labelProp
                      }
                    />
                  </ListItem>
                </Grid>
                <Grid style={{ textAlign: 'right' }} item xs={5}>
                  <RoundedButton
                    className={classes.customButtonDefault}
                    color="primary"
                    variant="contained"
                    onClick={() => setOpenPerfil(true)}
                  >
                    Ver perfil
                  </RoundedButton>
                  <RoundedButton
                    className={classes.customButtonDefault}
                    style={
                      adminMode
                        ? { backgroundColor: '#C3C3C3' }
                        : { backgroundColor: '#B80101' }
                    }
                    color="primary"
                    variant="contained"
                    onClick={handleForceCloseChat}
                    disabled={adminMode}
                  >
                    Encerrar conversa
                  </RoundedButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isLoading ? (
            <Grid style={{ marginTop: '2em' }} container spacing={3}>
              <Grid item xs={12} md={9} lg={8}>
                <Skeleton variant="rect" height={500} />
              </Grid>
              <Grid item xs={12} md={3} lg={4}>
                <Skeleton variant="rect" height={200} />
              </Grid>
            </Grid>
          ) : (
            <Grid style={{ marginTop: '2em' }} container spacing={3}>
              <Grid item xs={12} sm={12} md={9} lg={8}>
                <Grid style={{ marginBottom: '1em' }} container>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Nova Mensagem</Typography>
                  </Grid>
                </Grid>
                <Grid spacing={3} container className={classes.chatSection}>
                  <Grid item xs={12}>
                    <Card>
                      <List
                        ref={messagesEndRef}
                        onScroll={(e) => handleScroll(e)}
                        className={classes.messageArea}
                      >
                        {page < lastPage && (
                          <div
                            style={{
                              textAlign: 'center',
                              marginBottom: '15px',
                            }}
                          >
                            {isLoadingMore ? (
                              <CircularProgress size={30} color="primary" />
                            ) : (
                              <Button
                                color="primary"
                                onClick={handleLoadMoreMessages}
                              >
                                Carregar mais
                              </Button>
                            )}
                          </div>
                        )}

                        {messages?.map((messageData, idx) => (
                          <>
                            <div key={idx} className={classes.divDataSeparator}>
                              <span className={classes.dateMessageSeparator}>
                                {messageData.data}
                              </span>
                            </div>
                            {messageData.mensagens?.map((message, index) => (
                              <ListItem key={index}>
                                <Grid container>
                                  <Grid
                                    align={
                                      message.ProprietarioMensagem ||
                                      message.TipoUsuarioRemetente === 2
                                        ? 'right'
                                        : 'left'
                                    }
                                    item
                                    xs={12}
                                  >
                                    {editMessage === index ? (
                                      <Paper style={{ maxWidth: '80%' }}>
                                        <RoundedInput
                                          style={{ height: '100%' }}
                                          defaultValue={message.Mensagem}
                                          multiline
                                          size="small"
                                          variant="outlined"
                                          fullWidth
                                          onBlur={(e) =>
                                            setNewText(e.target.value)
                                          }
                                          onKeyPress={(e) =>
                                            handleEditMessageEnterPress(
                                              message,
                                              e.target.value,
                                              e
                                            )
                                          }
                                        />
                                        <IconButton onClick={handleCloseEdit}>
                                          <Close />
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            requestEditMessage(message, newText)
                                          }
                                        >
                                          <Check />
                                        </IconButton>
                                      </Paper>
                                    ) : (
                                      <>
                                        <Paper
                                          className={
                                            message.ProprietarioMensagem ||
                                            message.TipoUsuarioRemetente === 2
                                              ? classes.messageProp
                                              : classes.messageRem
                                          }
                                          aria-controls={`simple-menu${index}`}
                                          aria-haspopup="true"
                                          onClick={(e) =>
                                            handleOpenMenu(e, message)
                                          }
                                        >
                                          {message.TipoArquivo !== null &&
                                          message.TipoArquivo.includes(
                                            'image'
                                          ) ? (
                                            <div>
                                              {!message.Excluida ? (
                                                <div>
                                                  <a
                                                    download={
                                                      message.NomeArquivo
                                                    }
                                                    className={
                                                      classes.downloadFile
                                                    }
                                                    onClick={(e) =>
                                                      downloadFile(e)
                                                    }
                                                    href={`data:${
                                                      message.TipoArquivo
                                                    };base64,${
                                                      message.GuidArquivo ??
                                                      message.Mensagem
                                                    }`}
                                                  >
                                                    <img
                                                      src={`data:${message.TipoArquivo};base64,${message.GuidArquivo}`}
                                                      className={classes.img}
                                                      alt=""
                                                      onClick={(e) =>
                                                        downloadFile(e)
                                                      }
                                                      F
                                                    />
                                                    <ListItemText
                                                      primary={message.Mensagem}
                                                      secondary={RenderInfoMessage(
                                                        message
                                                      )}
                                                    />
                                                  </a>
                                                </div>
                                              ) : (
                                                <ListItemText
                                                  primary={RenderViewMessage(
                                                    message
                                                  )}
                                                  secondary={RenderInfoMessage(
                                                    message
                                                  )}
                                                />
                                              )}
                                            </div>
                                          ) : message.TipoArquivo !== null &&
                                            message.TipoArquivo.includes(
                                              'application'
                                            ) ? (
                                            <div>
                                              {!message.Excluida ? (
                                                <div>
                                                  <a
                                                    download={
                                                      message.NomeArquivo
                                                    }
                                                    className={
                                                      classes.downloadFile
                                                    }
                                                    onClick={(e) =>
                                                      downloadFile(e)
                                                    }
                                                    href={`data:${
                                                      message.TipoArquivo
                                                    };base64,${
                                                      message.GuidArquivo ??
                                                      message.Mensagem
                                                    }`}
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.infoFile
                                                      }
                                                    >
                                                      <DescriptionOutlinedIcon />
                                                      {message.NomeArquivo}
                                                    </Typography>
                                                  </a>
                                                  <ListItemText
                                                    primary={message.Mensagem}
                                                    secondary={RenderInfoMessage(
                                                      message
                                                    )}
                                                  />
                                                </div>
                                              ) : (
                                                <ListItemText
                                                  primary={RenderViewMessage(
                                                    message
                                                  )}
                                                  secondary={RenderInfoMessage(
                                                    message
                                                  )}
                                                />
                                              )}
                                            </div>
                                          ) : (
                                            <ListItemText
                                              primary={RenderViewMessage(
                                                message
                                              )}
                                              secondary={RenderInfoMessage(
                                                message
                                              )}
                                            />
                                          )}
                                        </Paper>
                                        <Menu
                                          id={`simple-menu${index}`}
                                          anchorEl={anchorEl}
                                          open={
                                            message.IdMensagem != null &&
                                            message.IdMensagem === menuOpenId &&
                                            editMessage == null &&
                                            (!adminMode ||
                                              message.TipoUsuarioRemetente ===
                                                2)
                                          }
                                          onClose={handleCloseMenu}
                                        >
                                          {(message.ProprietarioMensagem ||
                                            message.TipoUsuarioRemetente ===
                                              2) && (
                                            <MenuItem
                                              onClick={() =>
                                                handleGetInfoMessage(
                                                  message.IdMensagem,
                                                  null,
                                                  true
                                                )
                                              }
                                            >
                                              Detalhamento do envio
                                            </MenuItem>
                                          )}
                                          {(message.ProprietarioMensagem ||
                                            message.TipoUsuarioRemetente ===
                                              2) && (
                                            <MenuItem
                                              disabled={message.IdLote != null}
                                              onClick={() =>
                                                handleOpenEditMessage(
                                                  index,
                                                  message
                                                )
                                              }
                                            >
                                              Editar
                                            </MenuItem>
                                          )}
                                          {!message.ProprietarioMensagem &&
                                            message.TipoUsuarioRemetente !==
                                              2 && (
                                              <MenuItem
                                                onClick={() => {
                                                  handleCloseMenu();
                                                  handleOpenToForward(message);
                                                }}
                                              >
                                                Encaminhar
                                              </MenuItem>
                                            )}
                                          {(message.ProprietarioMensagem ||
                                            message.TipoUsuarioRemetente ===
                                              2) && (
                                            <MenuItem
                                              disabled={message.IdLote != null}
                                              onClick={() =>
                                                handleDeleteMessage(
                                                  message.IdMensagem
                                                )
                                              }
                                              style={{ color: '#B80101' }}
                                            >
                                              Excluir
                                            </MenuItem>
                                          )}
                                        </Menu>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              </ListItem>
                            ))}
                          </>
                        ))}
                      </List>
                      <Divider />
                      <Grid container style={{ padding: '5px 15px' }}>
                        <Grid item xs={10}>
                          <textarea
                            className={classes.input}
                            disabled={!chatAtivo || adminMode}
                            placeholder={
                              chatAtivo
                                ? 'Mensagem de até 5000 caracteres'
                                : 'Esta conversa foi encerrada'
                            }
                            ref={textRef}
                            onBlur={(e) => handleChangeText(e.target.value)}
                            onKeyPress={(e) =>
                              handleSendEnterPress(e.target.value, e)
                            }
                            inputProps={{ maxLength: '5000' }}
                          />
                        </Grid>
                        <Grid item xs={1} align="center">
                          <EnviarArquivo
                            disabledButton={adminMode}
                            setFile={setFile}
                            setBase64={setBase64}
                            onConfirmFile={sendFile}
                            isSend
                          />
                        </Grid>
                        <Grid item xs={1} align="right">
                          <IconButton
                            disabled={adminMode}
                            onClick={() => sendMessage(text, null, null)}
                            color="primary"
                          >
                            <Send fontSize="large" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={4} xl={4}>
                <Typography
                  style={{ marginBottom: '1em', fontSize: '1.25em' }}
                  color="primary"
                  variant="subtitle2"
                >
                  Atendimento
                </Typography>

                <AutoCompleteSearchInput
                  label="Selecione o(a) atendente"
                  disabled={!chatAtivo || adminMode}
                  size="small"
                  variant="outlined"
                  value={selectedSignature}
                  options={
                    selectedSignature &&
                    !signatureOptions.some((x) => x.id === selectedSignature.id)
                      ? [...signatureOptions, selectedSignature]
                      : signatureOptions
                  }
                  onValueChange={handleChageSignatureValue}
                  onTextChange={handleChangeSignatureText}
                  loading={signatureLoading}
                  PaperComponent={PaperComponent}
                />

                <Typography
                  style={{
                    marginBottom: '1em',
                    marginTop: '1em',
                    fontSize: '1.25em',
                  }}
                  color="primary"
                  variant="subtitle2"
                >
                  Configurações
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!chatAtivo || adminMode}
                      checked={activeClose}
                      onChange={handleWaitCloseChat}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <span style={{ fontSize: '0.85em' }}>
                      Pedir confirmação para encerrar conversa
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!chatAtivo || adminMode}
                      checked={allowAnswer}
                      onChange={() => handleAllowAnswer(!allowAnswer)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <span style={{ fontSize: '0.85em' }}>
                      Permitir respostas
                    </span>
                  }
                />
                <Typography
                  style={{
                    marginTop: '1em',
                    marginBottom: '0px',
                    fontSize: '1.25em',
                  }}
                  color="primary"
                  variant="subtitle2"
                  paragraph
                >
                  Detalhes
                </Typography>
                <ListItem
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingLeft: '0px',
                  }}
                >
                  <ListItemText
                    variant="contained"
                    primary="Setor"
                    secondary={sectorName}
                  />
                  <ListItemText
                    variant="contained"
                    primary="Status"
                    secondary={statusMap[activeClose][chatAtivo]}
                  />
                  <ListItemText
                    variant="contained"
                    primary="Tipo"
                    secondary={isLote ? 'Mensagem lote' : 'Mensagem'}
                  />
                </ListItem>
              </Grid>
            </Grid>
          )}
          <PerfilModal
            handleClose={() => setOpenPerfil(false)}
            open={openPerfil}
            data={perfilData}
            isLoading={loadingPerfil}
          />
          <ConfirmCloseModal
            handleClose={() => setOpenClose(false)}
            open={openClose}
            loading={loadingClose}
            confirm={confirmCloseChat}
            title="Encerrar conversa"
            message="Tem certeza? Essa ação não pode ser revertida"
          />
          <ConfirmCloseModal
            handleClose={() => setOpenDelete(null)}
            open={openDelete != null}
            loading={loadingDelete}
            confirm={() => handleConfirmDeleteMessage(openDelete)}
            title="Excluir mensagem"
            message="Tem certeza? Essa ação não pode ser revertida"
          />
          <DetailsModal
            handleClose={() => setOpenDetails(false)}
            open={openDetails}
            loading={loadingDetails}
            data={dataDetails}
            received={receivedDetails}
            visualized={visualizedDetails}
            loadingReceived={loadingReceived}
            loadingVisualized={loadingVisualized}
            loadMoreReceived={
              loadingDetails || pageReceived >= receivedDetails?.lastPage
                ? null
                : handleLoadMoreReceived
            }
            loadMoreVisualized={
              loadingDetails || pageVisualized >= visualizedDetails?.lastPage
                ? null
                : handleLoadMoreVisualized
            }
          />
          <DetailsModal
            handleClose={() => setOpenDetails(false)}
            open={openDetails}
            loading={loadingDetails}
            data={dataDetails}
            received={receivedDetails}
            visualized={visualizedDetails}
            loadingReceived={loadingReceived}
            loadingVisualized={loadingVisualized}
            loadMoreReceived={
              loadingDetails || pageReceived >= receivedDetails?.lastPage
                ? null
                : handleLoadMoreReceived
            }
            loadMoreVisualized={
              loadingDetails || pageVisualized >= visualizedDetails?.lastPage
                ? null
                : handleLoadMoreVisualized
            }
          />
          <ToForwardModal
            open={openToForwardModal}
            handleClose={() => setOpenToForwardModal(false)}
            idMessage={idMessage}
            nameSectorForwarded={sectorName}
          />
        </div>
      </div>
    </DefaultPage>
  );
};
